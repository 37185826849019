import React, { useRef, useEffect, useReducer, useCallback } from "react";
import { RenderGameEnded, RenderConfetti, RenderOwnPlayer, RenderOtherPlayer } from "../components/Game/UtilComponents";
import { handleMouseMove, setCursorPosition } from "../components/Game/Cursor/cursor.helpers";
import { renderCursorWithState } from "../components/Game/Cursor/RenderCursorWithState";
import {
  initiateSocket,
  subscribeToNewConnection,
  disconnectSocket,
  sendCursorPositionData,
  subscribeToCursorPositionsData,
  sendUserMouseDown,
  subscribeToUserMouseDown,
  sendUserMouseUp,
  subscribeToUserMouseUp,
  subscribeToActiveUsers,
  subscribeToAllUserPressingMouseDown,
  subscribeToWinnerArray,
  sendInterceptRestartGameStart,
  sendInterceptRestartGameCancel,
  subscribeToUserInterceptRestartGameStart,
  subscribeToUserInterceptRestartGameCancel,
  subscribeToAllUserInterceptRestartCircle,
  subscribeToUserJoinOrDisconnect,
} from "../utils/socket.helpers";

import {
  initialState,
  reducer,
  SET_TIMER_ANIMATION,
  SET_GAME_ENDED,
  SET_WINNER_ARRAY,
  SET_ACTIVE_USERS,
  SET_MY_SOCKETID,
  SET_PLAYERS_INTERCEPT_CIRCLE,
  RESET_STATE,
} from "../store";

function Game({ namespace, username }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { timerAnimation, gameEnded, winnerArray, activeUsers, mySocketId, playersInterceptingRestartCircle } = state;

  const cursors = useRef([]);

  useEffect(() => {
    if (namespace) initiateSocket(namespace, username);

    subscribeToNewConnection((err, mySocketId) => {
      if (err) return;
      initiatetOwnUser(mySocketId);
    });

    subscribeToUserJoinOrDisconnect();

    subscribeToActiveUsers((err, users) => {
      if (err) return;
      recordActiveUsers(users);
    });

    subscribeToCursorPositionsData((err, cords) => {
      if (err) return;
      handleSetCursorPosition(cords);
    });

    subscribeToUserMouseDown((err, id) => {
      if (err) return;
      userIsPressingMouseDown(id);
    });

    subscribeToUserMouseUp((err, player) => {
      if (err) return;
      userIsPressingMouseUp(player);
    });

    subscribeToAllUserPressingMouseDown((err, boolean) => {
      if (err) return;
      allUserPressingMouseDown(boolean);
    });

    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    // winner
    subscribeToWinnerArray((err, data) => {
      if (err) return;
      handleprocessWinners(data);
    });
  }, [mySocketId, winnerArray]);

  useEffect(() => {
    // restart
    subscribeToUserInterceptRestartGameStart((err, id) => {
      if (err) return;
      userIsInterceptingRestartGame(id);
    });

    subscribeToUserInterceptRestartGameCancel((err, id) => {
      if (err) return;
      userIsInterceptingRestartGame(id);
    });

    subscribeToAllUserInterceptRestartCircle((err) => {
      if (err) return;
      allUserInterceptRestartCircle();
    });
  }, [playersInterceptingRestartCircle, gameEnded]);

  // init Functions
  function initiatetOwnUser(id) {
    dispatch({ type: SET_MY_SOCKETID, payload: id });
  }

  function recordActiveUsers(users) {
    dispatch({ type: SET_ACTIVE_USERS, payload: users });
    syncInitCursorPositionOfOtherUsers(users);
  }

  // sync the positions of other players when you join a game
  function syncInitCursorPositionOfOtherUsers(users) {
    users.forEach((user) => {
      if (user.id !== mySocketId) {
        handleSetCursorPosition(user);
      }
    });
  }

  // Mouse Move
  function onMouseMove(ev) {
    if (!mySocketId) return;

    const x = ev.pageX;
    const y = ev.pageY;
    const rect = cursors.current[`${mySocketId}`].firstChild.getBoundingClientRect();

    handleMouseMove({
      x,
      y,
      rect,
      gameEnded,
      sendCursorPositionData,
      sendInterceptRestartGameStart,
      sendInterceptRestartGameCancel,
    });
  }

  function handleSetCursorPosition(user) {
    setCursorPosition({ user, cursors, activeUsers });
  }

  // Mouse Down
  function handleMouseDown() {
    sendUserMouseDown(); // send to Socket.io
  }

  function userIsPressingMouseDown(user) {
    if (user.id) cursors.current[`${user.id}`].firstChild.style.backgroundColor = `${user.clr}`;
  }

  // Mouse Up
  function handleMouseUp() {
    sendUserMouseUp(); // send to Socket.io
  }

  function userIsPressingMouseUp(id) {
    if (id) cursors.current[`${id}`].firstChild.style.backgroundColor = "transparent";
  }

  // Events
  // All Users Pressing Mouse Down
  function allUserPressingMouseDown(bln) {
    dispatch({ type: SET_TIMER_ANIMATION, payload: true });

    if (bln === false) dispatch({ type: SET_TIMER_ANIMATION, payload: false });
  }

  // Restart Games
  function userIsInterceptingRestartGame(users) {
    const amount = users.filter((user) => user.isInterceptiongRestartCircle).length;
    dispatch({ type: SET_PLAYERS_INTERCEPT_CIRCLE, payload: amount });
  }

  function allUserInterceptRestartCircle() {
    // RestartGame
    dispatch({ type: RESET_STATE });
  }

  function renderOtherPlayers() {
    if (!activeUsers || !mySocketId) return;
    const otherUsers = activeUsers.filter((user) => user.id !== mySocketId);

    return otherUsers.map((user) => {
      return (
        <RenderOtherPlayer user={user} cursors={cursors}>
          {renderCursorWithState({
            id: user.id,
            winnerArray,
            gameEnded,
            timerAnimation,
            isMobile: user.isMobile,
          })}
        </RenderOtherPlayer>
      );
    });
  }

  function renderOwnPlayer() {
    if (!activeUsers || !mySocketId) return;

    return (
      <RenderOwnPlayer activeUsers={activeUsers} mySocketId={mySocketId} cursors={cursors}>
        {renderCursorWithState({
          id: mySocketId,
          winnerArray,
          gameEnded,
          timerAnimation,
          isMobile: false,
        })}
      </RenderOwnPlayer>
    );
  }

  function handleprocessWinners(data) {
    if (!mySocketId) return;

    const winnerArray = data.map((user, index) => {
      return { id: user.id, position: index };
    });
    dispatch({ type: SET_WINNER_ARRAY, payload: winnerArray });
    dispatch({ type: SET_GAME_ENDED, payload: true });
  }

  function renderGameEnded() {
    return (
      <>
        <RenderConfetti winnerArray={winnerArray} mySocketId={mySocketId}></RenderConfetti>
        <RenderGameEnded
          playersInterceptingRestartCircle={playersInterceptingRestartCircle}
          activeUsersLength={activeUsers.length}
        ></RenderGameEnded>
      </>
    );
  }

  return (
    <>
      <div
        className="app"
        onMouseMove={(ev) => onMouseMove(ev)}
        onMouseDown={(ev) => handleMouseDown(ev)}
        onMouseUp={(ev) => handleMouseUp(ev)}
      >
        {gameEnded && renderGameEnded()}
        {renderOwnPlayer()}
        {renderOtherPlayers()}
      </div>
    </>
  );
}

export default Game;
