// Init STATE
export const initialState = {
  timerAnimation: false,
  gameEnded: false,
  winnerArray: null,
  activeUsers: [],
  mySocketId: null,
  playersInterceptingRestartCircle: null,
};

// ACTIONS
export const SET_TIMER_ANIMATION = "setTimerAnimation";
export const SET_GAME_ENDED = "setGameEnded";
export const SET_WINNER_ARRAY = "setWinnerArray";
export const SET_ACTIVE_USERS = "setActiveUsers";
export const SET_MY_SOCKETID = "setMySocketId";
export const SET_PLAYERS_INTERCEPT_CIRCLE = "setPlayersInterceptingRestartCircle";
export const RESET_STATE = "resetState";

// REDUCER
export function reducer(state, action) {
  switch (action.type) {
    case SET_TIMER_ANIMATION:
      return {
        ...state,
        timerAnimation: action.payload,
      };
    case SET_GAME_ENDED:
      return {
        ...state,
        gameEnded: action.payload,
      };
    case SET_WINNER_ARRAY:
      return {
        ...state,
        winnerArray: action.payload,
      };
    case SET_ACTIVE_USERS:
      return {
        ...state,
        activeUsers: [...action.payload],
      };
    case SET_MY_SOCKETID:
      return {
        ...state,
        mySocketId: action.payload,
      };
    case SET_PLAYERS_INTERCEPT_CIRCLE:
      return {
        ...state,
        playersInterceptingRestartCircle: action.payload,
      };
    case RESET_STATE: {
      return {
        ...state,
        timerAnimation: false,
        gameEnded: false,
        winnerArray: null,
        playersInterceptingRestartCircle: null,
      };
    }
    default:
      console.error("Something went wrong dispatching the Store");
  }
}
