import React, { useRef, useEffect, useReducer, useState, useCallback } from "react";
import { renderCursorWithState } from "../components/Game/Cursor/RenderCursorWithState";
import MenuMobile from "../components/Modal/Menu_Mobile";
import { useSpring } from "@react-spring/web";
import { createUseGesture, dragAction } from "@use-gesture/react";
import {
  RenderOtherPlayer,
  RenderGameEnded,
  RenderConfetti,
  RenderOwnPlayerMobile,
} from "../components/Game/UtilComponents";
import { handleDrag } from "../components/Game/Cursor/cursor.helpers";
import {
  initiateSocket,
  subscribeToNewConnection,
  disconnectSocket,
  sendCursorPositionData,
  subscribeToCursorPositionsData,
  sendUserMouseDown,
  subscribeToUserMouseDown,
  sendUserMouseUp,
  subscribeToUserMouseUp,
  subscribeToActiveUsers,
  subscribeToAllUserPressingMouseDown,
  subscribeToWinnerArray,
  sendInterceptRestartGameStart,
  sendInterceptRestartGameCancel,
  subscribeToUserInterceptRestartGameStart,
  subscribeToUserInterceptRestartGameCancel,
  subscribeToAllUserInterceptRestartCircle,
  subscribeToUserJoinOrDisconnect,
} from "../utils/socket.helpers";

import {
  initialState,
  reducer,
  SET_TIMER_ANIMATION,
  SET_GAME_ENDED,
  SET_WINNER_ARRAY,
  SET_ACTIVE_USERS,
  SET_MY_SOCKETID,
  SET_PLAYERS_INTERCEPT_CIRCLE,
  RESET_STATE,
} from "../store";

const useGesture = createUseGesture([dragAction]);

function GameMobile({ namespace, username }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { timerAnimation, gameEnded, winnerArray, activeUsers, mySocketId, playersInterceptingRestartCircle } = state;

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const ref = React.useRef();
  const cursors = useRef([]);

  // Drag Animation
  const [position, api] = useSpring(() => ({
    x: 0,
    y: 0,
    config: { tension: 300 },
  }));

  useGesture(
    {
      onDragStart: ({ event }) => handleDragStart(event),
      onDrag: ({ pinching, cancel, offset: [x, y], ...state }) => {
        handleOnDrag(state, x, y);
      },
      onDragEnd: () => handleDragEnd(),
    },
    {
      target: ref,
      drag: {
        from: () => [position.x.get(), position.y.get()],
        bounds: {
          top: 0,
          left: 0,
          bottom: window.document.documentElement.clientHeight,
          right: window.document.documentElement.clientWidth,
        },
      },
    }
  );

  useEffect(() => {
    if (namespace) initiateSocket(namespace, username);

    subscribeToNewConnection((err, mySocketId) => {
      if (err) return;
      initiatetOwnUser(mySocketId);
    });

    subscribeToUserJoinOrDisconnect();

    subscribeToActiveUsers((err, users) => {
      if (err) return;
      recordActiveUsers(users);
    });

    subscribeToUserMouseDown((err, id) => {
      if (err) return;
      userIsPressingMouseDown(id);
    });

    subscribeToUserMouseUp((err, player) => {
      if (err) return;
      userIsPressingMouseUp(player);
    });

    subscribeToAllUserPressingMouseDown((err, boolean) => {
      if (err) return;
      allUserPressingMouseDown(boolean);
    });

    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    subscribeToCursorPositionsData((err, cords) => {
      if (err) return;
      setCursorPosition(cords);
    });
    // winner
    subscribeToWinnerArray((err, data) => {
      if (err) return;
      handleprocessWinners(data);
    });
  }, [mySocketId, winnerArray]);

  useEffect(() => {
    // restart
    subscribeToUserInterceptRestartGameStart((err, id) => {
      if (err) return;
      userIsInterceptingRestartGame(id);
    });

    subscribeToUserInterceptRestartGameCancel((err, id) => {
      if (err) return;
      userIsInterceptingRestartGame(id);
    });

    subscribeToAllUserInterceptRestartCircle((err, id) => {
      if (err) return;
      allUserInterceptRestartCircle();
    });
  }, [playersInterceptingRestartCircle, gameEnded]);

  // init Functions
  function initiatetOwnUser(id) {
    dispatch({ type: SET_MY_SOCKETID, payload: id });
  }

  function recordActiveUsers(users) {
    dispatch({ type: SET_ACTIVE_USERS, payload: users });
  }

  function setCursorPosition(user) {
    if (user.id && mySocketId) {
      if (user.id === mySocketId) {
        return;
      }
      setCursorPosition({ user, cursors, activeUsers, radius: 40 });
    }
  }

  function handleOnDrag(state, x, y) {
    if (isMobileMenuOpen === true || !mySocketId) return;

    const rect = cursors.current[`${mySocketId}`].firstChild.getBoundingClientRect();
    const rectPositionRightCorner = x + Math.floor(rect.width);
    const rectPositionBottom = y + Math.floor(rect.height);

    if (
      rectPositionRightCorner >= window.document.documentElement.clientWidth ||
      rectPositionBottom >= window.document.documentElement.clientHeight
    ) {
      return;
    }

    api.start({ x, y });

    handleDrag({
      x,
      y,
      stateX: state.xy[0],
      stateY: state.xy[1],
      rect,
      gameEnded,
      sendCursorPositionData,
      sendInterceptRestartGameStart,
      sendInterceptRestartGameCancel,
    });
  }

  function handleDragStart(event) {
    if (isMobileMenuOpen === true || event.target.className === "actionBtn") return;

    if (!gameEnded) sendUserMouseDown(); // send to Socket.io
  }

  function handleDragEnd() {
    if (isMobileMenuOpen === true) return;

    if (!gameEnded) sendUserMouseUp(); // send to Socket.io
  }

  function userIsPressingMouseDown(user) {
    if (user.id) cursors.current[`${user.id}`].firstChild.style.backgroundColor = `${user.clr}`;
  }

  function userIsPressingMouseUp(id) {
    if (id) cursors.current[`${id}`].firstChild.style.backgroundColor = "transparent";
  }

  // Events
  // All Users Pressing Mouse Down
  function allUserPressingMouseDown(bln) {
    dispatch({ type: SET_TIMER_ANIMATION, payload: true });

    if (bln === false) dispatch({ type: SET_TIMER_ANIMATION, payload: false });
  }

  function userIsInterceptingRestartGame(users) {
    const amount = users.filter((user) => user.isInterceptiongRestartCircle).length;
    dispatch({ type: SET_PLAYERS_INTERCEPT_CIRCLE, payload: amount });
  }

  function allUserInterceptRestartCircle() {
    // RestartGame
    dispatch({ type: RESET_STATE });
  }

  function renderOtherPlayers() {
    if (!activeUsers || !mySocketId) return;
    const otherUsers = activeUsers.filter((user) => user.id !== mySocketId);

    return otherUsers.map((user) => {
      return (
        <RenderOtherPlayer user={user} cursors={cursors}>
          {renderCursorWithState({
            id: mySocketId,
            winnerArray,
            gameEnded,
            timerAnimation,
            isMobile: user.isMobile,
          })}
        </RenderOtherPlayer>
      );
    });
  }

  function renderOwnPlayer() {
    if (!activeUsers || !mySocketId) return;

    return (
      <RenderOwnPlayerMobile activeUsers={activeUsers} mySocketId={mySocketId} cursors={cursors} position={position}>
        {renderCursorWithState({
          id: mySocketId,
          winnerArray,
          gameEnded,
          timerAnimation,
          isMobile: true,
        })}
      </RenderOwnPlayerMobile>
    );
  }

  function handleprocessWinners(data) {
    if (!mySocketId) return;

    const winnerArray = data.map((user, index) => {
      return { id: user.id, position: index };
    });
    dispatch({ type: SET_WINNER_ARRAY, payload: winnerArray });
    dispatch({ type: SET_GAME_ENDED, payload: true });
  }

  function renderGameEnded() {
    return (
      <>
        <RenderConfetti winnerArray={winnerArray} mySocketId={mySocketId}></RenderConfetti>
        <RenderGameEnded
          playersInterceptingRestartCircle={playersInterceptingRestartCircle}
          activeUsersLength={activeUsers.length}
        ></RenderGameEnded>
      </>
    );
  }

  /*   MOBILE FUNCTIONS */
  function handleMobileMenuOpenState(isOpen) {
    setMobileMenuOpen(isOpen);
  }

  return (
    <>
      <div className="app" ref={ref}>
        {gameEnded && renderGameEnded()}
        <MenuMobile openIndicator={handleMobileMenuOpenState}></MenuMobile>
        {renderOwnPlayer()}
        {renderOtherPlayers()}
      </div>
    </>
  );
}

export default GameMobile;
