export function WinnerCircle() {
    return (
      <>
        <div className="point_winner" style={{ "--i": 1 }}></div>
        <div className="point_winner" style={{ "--i": 2 }}></div>
        <div className="point_winner" style={{ "--i": 3 }}></div>
        <div className="point_winner" style={{ "--i": 4 }}></div>
        <div className="point_winner" style={{ "--i": 5 }}></div>
        <div className="point_winner" style={{ "--i": 6 }}></div>
      </>
    );
  }