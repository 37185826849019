// Browser

export function handleMouseMove(args) {
  const {
    x,
    y,
    rect,
    gameEnded,
    sendCursorPositionData,
    sendInterceptRestartGameStart,
    sendInterceptRestartGameCancel,
  } = args;
  const width = window.visualViewport.width;
  const height = window.visualViewport.height;

  const percentageX = (x / width) * 100;
  const percentageY = (y / height) * 100;
  const data = { x: percentageX, y: percentageY };

  sendCursorPositionData(data); // send to Socket.io

  const gameEndDimensions = window.screen.width * 0.25 - Math.floor(rect.width);

  if (gameEnded === true) {
    checkIfUserInterceptsRestartCircle({
      x,
      y,
      gameEndDimensions,
      sendInterceptRestartGameStart,
      sendInterceptRestartGameCancel,
    });
  }
}

// Mobile
export function handleDrag(args) {
  const {
    x,
    y,
    stateX,
    stateY,
    rect,
    gameEnded,
    sendCursorPositionData,
    sendInterceptRestartGameStart,
    sendInterceptRestartGameCancel,
  } = args;
  const width = window.document.documentElement.clientWidth;
  const height = window.document.documentElement.clientHeight;

  const percentageX = (stateX / width) * 100;
  const percentageY = (stateY / height) * 100;
  const data = { x: percentageX, y: percentageY };

  sendCursorPositionData(data); // send to Socket.io

  const gameEndDimensions = window.document.documentElement.clientWidth * 0.45 - Math.floor(rect.width);

  if (gameEnded === true) {
    checkIfUserInterceptsRestartCircle({
      x,
      y,
      gameEndDimensions,
      sendInterceptRestartGameStart,
      sendInterceptRestartGameCancel,
    });
  }
}

function checkIfUserInterceptsRestartCircle(args) {
  const { x, y, gameEndDimensions, sendInterceptRestartGameStart, sendInterceptRestartGameCancel } = args;

  // restartGame Logic
  if (x < gameEndDimensions && y <= gameEndDimensions) {
    return sendInterceptRestartGameStart(); // send to Socket.io
  }
  if (x >= gameEndDimensions || y >= gameEndDimensions) {
    return sendInterceptRestartGameCancel(); // send to Socket.io
  }
}

export function setCursorPosition(args) {
  const { user, cursors, activeUsers, radius = 90 } = args;

  if (!user || !cursors.current[`${user.id}`] || !activeUsers) return;

  const x = user.x;
  const y = user.y;
  const id = user.id;

  cursors.current[`${id}`].style.top = `+${y}%`;
  cursors.current[`${id}`].style.left = `+${x}%`;
  // center cursor
  cursors.current[`${id}`].style.transform = `translate(-${radius}px, -${radius}px)`;
}
