import React from "react";
import Confetti from "react-confetti";
import { isMobile } from "react-device-detect";
import { animated } from "@react-spring/web";

export function renderName(name, isMobile) {
  return (
    <p>
      {isMobile ? "📱" : null} {name}
    </p>
  );
}

export function RenderGameEnded({ playersInterceptingRestartCircle, activeUsersLength }) {
  return (
    <div className="gameEnded">
      <h3>Game Ended, come here to restart 🎉</h3>
      <p>
        {playersInterceptingRestartCircle ? playersInterceptingRestartCircle : 0}/{activeUsersLength}
      </p>
    </div>
  );
}

export function RenderConfetti({ winnerArray, mySocketId }) {
  if (!winnerArray || !mySocketId) return;

  const userWithPosition = winnerArray.filter((user) => user.id === mySocketId);
  const isWinner = userWithPosition[0].position === 0;

  const width = window.document.documentElement.clientWidth;
  const heigth = window.document.documentElement.clientHeight;
  const numberOfPieces = isMobile ? 100 : 200;

  if (isWinner) {
    return <Confetti width={width} height={heigth} numberOfPieces={numberOfPieces} />;
  }

  return null;
}

export function RenderOwnPlayer({ activeUsers, mySocketId, cursors, ...props }) {
  const ownUser = activeUsers.filter((user) => user.id === mySocketId);
  const username = `(${ownUser[0]?.username}) - It's you`;
  
  return (
    <div
      ref={(element) => {
        cursors.current[`${mySocketId}`] = element;
      }}
      className="cursor_wrapper"
      key={mySocketId}
    >
      {props.children}
      {renderName(username)}
    </div>
  );
}

export function RenderOwnPlayerMobile({ activeUsers, mySocketId, cursors, position, ...props }) {
  const ownUser = activeUsers.filter((user) => user.id === mySocketId);
  const username = `(${ownUser[0]?.username}📱) - It's you`;

  return (
    <animated.div
      className="cursor_wrapper"
      ref={(element) => {
        cursors.current[`${mySocketId}`] = element;
      }}
      key={mySocketId}
      style={{
        ...position,
      }}
      key={mySocketId}
    >
      {props.children}
      {renderName(username)}
    </animated.div>
  );
}

export function RenderOtherPlayer({ user, cursors, ...props }) {
  return (
    <div
      ref={(element) => {
        cursors.current[`${user.id}`] = element;
      }}
      className="cursor_wrapper"
      key={user.id}
    >
      {props.children}
      {renderName(user.username, user.isMobile)}
    </div>
  );
}
